exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ads-message-js": () => import("./../../../src/pages/ads-message.js" /* webpackChunkName: "component---src-pages-ads-message-js" */),
  "component---src-pages-adsblock-js": () => import("./../../../src/pages/adsblock.js" /* webpackChunkName: "component---src-pages-adsblock-js" */),
  "component---src-pages-courselist-js": () => import("./../../../src/pages/courselist.js" /* webpackChunkName: "component---src-pages-courselist-js" */),
  "component---src-pages-dcma-js": () => import("./../../../src/pages/dcma.js" /* webpackChunkName: "component---src-pages-dcma-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-football-js": () => import("./../../../src/pages/football.js" /* webpackChunkName: "component---src-pages-football-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-page-js": () => import("./../../../src/pages/job-page.js" /* webpackChunkName: "component---src-pages-job-page-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-tech-js": () => import("./../../../src/pages/Tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-test-1-js": () => import("./../../../src/pages/test1.js" /* webpackChunkName: "component---src-pages-test-1-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-password-js": () => import("./../../../src/pages/user/password.js" /* webpackChunkName: "component---src-pages-user-password-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-video-course-js": () => import("./../../../src/pages/video_course.js" /* webpackChunkName: "component---src-pages-video-course-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-viewpage-js": () => import("./../../../src/pages/viewpage.js" /* webpackChunkName: "component---src-pages-viewpage-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

